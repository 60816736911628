import React, { useState, useEffect } from "react";
import {
    web3,
    web3getAccount,
    CheckBalance,
    poolsInfo,
    adminWithdraw
} from "../../web3/web3Provider";
import { Card, FormControl, Modal, InputGroup, Button, Spinner } from 'react-bootstrap'
import Swal from "sweetalert2";

const ModlaInfo = () => {
    const [show, setShow] = useState(false);
    const [poolInfos, setPoolsInfos] = useState([]);
    const [poolBalance, setPoolsBalance] = useState([]);


    /* Select Pools to WithdrawPBX */
    const [poolsWithdraw, setPoolsWithdraw] = useState(0);
    const [amountsWithdraw, setAmountsWithdraw] = useState(0);
    
    const [iconWithdrawLoading0, setIconWithdrawLoading0] = useState(false);
    const [iconWithdrawLoading1, setIconWithdrawLoading1] = useState(false);
    const [iconWithdrawLoading2, setIconWithdrawLoading2] = useState(false);
    const [iconWithdrawLoading3, setIconWithdrawLoading3] = useState(false);

    const _adminWithdraw = async (pools) => {
        if(pools === 0) setIconWithdrawLoading0(true);
        if(pools === 1) setIconWithdrawLoading1(true);
        if(pools === 2) setIconWithdrawLoading2(true);
        if(pools === 3) setIconWithdrawLoading3(true);

        await adminWithdraw(pools, amountsWithdraw).then((resTXN) => {
            Swal.fire({
                icon: "success",
                title: `Withdraw [ ${amountsWithdraw} PBX ] SUCCESS`,
                footer: "<a href='https://bscscan.com/tx/" + resTXN + "' target='_blank'>TXN</a>"
            }).then(() => window.location.reload())
        }).catch((error) => {
            if(pools === 0) setIconWithdrawLoading0(false);
            if(pools === 1) setIconWithdrawLoading1(false);
            if(pools === 2) setIconWithdrawLoading2(false);
            if(pools === 3) setIconWithdrawLoading3(false);
        })
    }

    useEffect(() => {
        const fetchAllPools = async () => {
            let prePoolsInfo = [];
            await poolsInfo(1).then((res) => setPoolsInfos(prePoolsInfo.push(res)))
            await poolsInfo(2).then((res) => setPoolsInfos(prePoolsInfo.push(res)))
            await poolsInfo(3).then((res) => setPoolsInfos(prePoolsInfo.push(res)))
            await poolsInfo(4).then((res) => setPoolsInfos(prePoolsInfo.push(res)))
            setPoolsInfos(prePoolsInfo)
            // console.log(prePoolsInfo)

            let prePoolsBalance = [];

            await CheckBalance(prePoolsInfo[0].address).then((balance) => prePoolsBalance.push(web3.utils.fromWei(balance)) )
            await CheckBalance(prePoolsInfo[1].address).then((balance) => prePoolsBalance.push(web3.utils.fromWei(balance)) )
            await CheckBalance(prePoolsInfo[2].address).then((balance) => prePoolsBalance.push(web3.utils.fromWei(balance)) )
            await CheckBalance(prePoolsInfo[3].address).then((balance) => prePoolsBalance.push(web3.utils.fromWei(balance)) )
            setPoolsBalance(prePoolsBalance)
            // console.log(prePoolsBalance)
        }
        fetchAllPools()
    }, [])

    return (
        <>
            {
                poolInfos.length > 0
                    ? (
                        <Button className="btnWeb3" style={{ height: "50px" }} onClick={() => setShow(true)}>
                            Staking Info
                        </Button>
                    )
                    : ""
            }

            <Modal show={show} fullscreen={true} onHide={() => setShow(false)} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Staking Info</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    {
                        poolInfos.length > 0
                            ?
                            poolInfos.map((item, index) => (
                                <Card key={index} className="btnWeb3 mb-5">
                                    <Card.Body>
                                        <h5>Contract Address : <a href={"https://bscscan.com/address/" + item.address} target="_blank">{item.address}</a></h5>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">(%) APR</InputGroup.Text>
                                            <FormControl className="text-center" value={item.APR} readOnly />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">Days Lock</InputGroup.Text>
                                            <FormControl className="text-center" value={item.DaysLock} readOnly />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <InputGroup.Text id="basic-addon1">PBX Balance</InputGroup.Text>
                                            <FormControl className="text-center" value={Number(poolBalance[index]).toLocaleString()} readOnly />
                                        </InputGroup>
                                        <InputGroup className="mb-3">
                                            <FormControl className="text-center" placeholder="Amount PBX to Withdraw" onInput={(e) => setAmountsWithdraw(e.target.value) } />
                                            <Button variant="light" style={{ height: "50px" }} className="goldenBack btnWeb3 w-100" onClick={() => _adminWithdraw(index) }>
                                                { 
                                                    index === 0
                                                    ? iconWithdrawLoading0 === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""
                                                    : index === 1
                                                    ? iconWithdrawLoading1 === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""
                                                    : index === 2
                                                    ? iconWithdrawLoading2 === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""
                                                    : index === 3
                                                    ? iconWithdrawLoading3 === true ? (<><Spinner animation="border" variant="light" size="sm" />{" "}</>) : ""
                                                    : ""
                                                }

                                                Withdraw
                                            </Button>
                                        </InputGroup>
                                    </Card.Body>
                                </Card>
                            ))
                            : ""
                    }

                </Modal.Body>
                <Modal.Footer className="d-flex justify-content-center">
                    <Button variant="secondary" className="btnWeb3" onClick={() => setShow(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export {
    ModlaInfo
}