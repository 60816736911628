import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Home from './Pages/Home';
import StakingList from './Pages/StakingList';
import StakingCard from './Pages/StakingCard';


function App() {
  const Error404 = () => <h1 className="text-center"><br/><br/><img src="https://cdn4.iconfinder.com/data/icons/gradient-5/50/453-512.png" width="35%" alt="404" /><br/><br/></h1>

  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/list" component={StakingList} />
        <Route exact path="/card" component={StakingCard} />
        <Route exact path="*" component={StakingList} />
      </Switch>
    </>
    
  );
}

export default App;
