import React, { useState, useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import {
    web3,
    web3getAccount,
    //   CheckBalance,
    chkApprove,
    poolsInfo,
    userInfo
} from "../web3/web3Provider";


import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { List, Box, AlarmFill, CurrencyExchange } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

// import Swal from 'sweetalert2'

import Header from '../Components/Header';
import FooterAPR from '../Components/FooterAPR';
import Pools from '../Components/Pools';
import PoolsCard from '../Components/PoolsCard';
import { ModlaInfo } from '../Components/Modals/modal';

function Staking() {
    /*
      if(typeof window.ethereum === 'undefined') {
        window.location.href = "./";
        throw new Error();
      }*/
    const [poolInfos, setPoolsInfos] = useState([]);
    const [poolApprove, setPoolApprove] = useState([]);
    const [stakerInfo, setStakerInfo] = useState([]);
    const [maxTotalTVL, setMaxTotalTVL] = useState(0);
    const [isAdmin, setIsAdmin] = useState(false);





    useEffect(() => {
        let stakerInfos = []
        const fetchAllPools = async () => {
            let prePoolsInfo = [];
            let _maxTotalTVL = 0;
            await poolsInfo(1).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(2).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(3).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(4).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            setPoolsInfos(prePoolsInfo)
            console.log(prePoolsInfo)
            setMaxTotalTVL(_maxTotalTVL)
        }
        const fetchApproveOfAllPools = async () => {
            let preChkApprove = []
            await userInfo(1).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(2).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(3).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(4).then((res) => setStakerInfo(stakerInfos.push(res)))
            setStakerInfo(stakerInfos)
            // await chkApprove(1).then((res) => poolApprove.pool1 = res )
            // await chkApprove(2).then((res) => poolApprove.pool2 = res )
            // await chkApprove(3).then((res) => poolApprove.pool3 = res )
            await chkApprove(1).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(2).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(3).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(4).then((res) => setPoolApprove(preChkApprove.push(res)))
            setPoolApprove(preChkApprove)
            await web3getAccount().then((acc) => {
                if(acc.toLowerCase() === "0x123358F192afE0a2128321595e7b50CB45Ca8975".toLowerCase()) {
                    setIsAdmin(true)
                }
            })
        }
        fetchAllPools()
        fetchApproveOfAllPools()
        console.log(stakerInfo)
        console.log(poolApprove)

    }, []);


    return (

        <>

            <Header />

            <div className="text-center">
                <p>
                    <img src="pbx.gif" alt="logo" />
                </p>
                <br />
                <br />

                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        color: "rgb(90, 96, 108)",
                    }}
                >
                    <p>
                        { isAdmin === true ? <ModlaInfo /> : "" }
                    </p>

                    <p>
                        <Link to="/list" title="List View">
                            <Button variant="warning btnWeb3"><List style={{ fontSize: "18px"}} /> List View</Button>
                        </Link>
                        {" "}
                        <Link to="/card" title="Card View">
                            <Button variant="secondary btnWeb3"><Box style={{ fontSize: "18px"}} /> Card View</Button>
                        </Link>
                    </p>
                    <br/>

                    <Container style={{ fontSize: "18px", maxWidth: "838px" }}>

                        <Row>
                            <Col lg="12" className="d-flex justify-content-between" style={{ padding: ".85rem" }}>
                                <Accordion className="w-100">
                                    <Accordion.Item eventKey="0" className="itemWeb3">
                                        <Accordion.Header style={{ position: "relative" }}>
                                            <div>
                                                <div style={{ position: "absolute", top: "25px", left: "10px" }}><img src="PBX.png" alt="logo" height="30" /></div>
                                                <div style={{ position: "absolute", top: "40px", left: "24px" }}><img src="PBX.png" alt="logo" height="48" /></div>
                                                <div style={{ position: "absolute", top: "15px", left: "80px" }}>
                                                    <p><small>Stake</small> <b>PBX</b></p>
                                                    <br />
                                                    <p><small>Earn</small> <b>PBX</b></p>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ position: "absolute", top: "15px", right: "50px" }}>
                                                    {/* <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[0].APR : ""}%</b></p> */}
                                                    <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[0].APR : ""}%</b></p>
                                                    <br />
                                                    <p><AlarmFill /> <b>{poolInfos.length > 0 ? poolInfos[0].DaysLock : ""} Days</b></p>
                                                </div>
                                            </div>
                                            <br /><br /><br /><br />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                stakerInfo.length > 0
                                                    ? (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[0] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[0].DaysLock : ""}
                                                                stakerDeposit={stakerInfo[0].deposits}
                                                                stakerPendingsRewards={stakerInfo[0].pendingsRewards}
                                                                stakerTimeLock={stakerInfo[0].timelocks}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[0].address : ""}
                                                                poolsId={1}
                                                            />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[0] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[0].DaysLock : ""}
                                                                stakerDeposit={0}
                                                                stakerPendingsRewards={0}
                                                                stakerTimeLock={0}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[0].address : ""}
                                                                poolsId={1}
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="mt-3 text-start text-danger" style={{
                                                padding: "10px 10px 10px 10px",
                                                borderLeft: "4px solid #d8dbe0", borderRadius: "5px", border: "1px solid red"
                                            }}>
                                                <p style={{ marginLeft: "10px", fontSize: "12px" }}>After first 60% APR 180 day-locked, %APR pays 30%APR automatically</p>
                                                <p style={{ marginLeft: "10px", fontSize: "12px" }}>New staking pays 30% APR 90 day-locked</p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="1" className="itemWeb3">
                                        <Accordion.Header style={{ position: "relative" }}>
                                            <div>
                                                <div style={{ position: "absolute", top: "25px", left: "10px" }}><img src="PBX.png" alt="logo" height="30" /></div>
                                                <div style={{ position: "absolute", top: "40px", left: "24px" }}><img src="PBX.png" alt="logo" height="48" /></div>
                                                <div style={{ position: "absolute", top: "15px", left: "80px" }}>
                                                    <p><small>Stake</small> <b>PBX</b></p>
                                                    <br />
                                                    <p><small>Earn</small> <b>PBX</b></p>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ position: "absolute", top: "15px", right: "50px" }}>
                                                    {/* <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[1].APR : ""}%</b></p> */}
                                                    <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[0].APR : ""}%</b></p>
                                                    <br />
                                                    {/* <p><AlarmFill /> <b>{poolInfos.length > 0 ? poolInfos[1].DaysLock : ""} Days</b></p> */}
                                                    <p><AlarmFill /> <b>120 Days</b></p>
                                                </div>
                                            </div>
                                            <br /><br /><br /><br />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                stakerInfo.length > 0
                                                    ? (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[1] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVL) : ""}
                                                                // timelock={poolInfos.length > 0 ? poolInfos[1].DaysLock : ""}
                                                                timelock={120}
                                                                stakerDeposit={stakerInfo[1].deposits}
                                                                stakerPendingsRewards={stakerInfo[1].pendingsRewards}
                                                                stakerTimeLock={stakerInfo[1].timelocks}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[1].address : ""}
                                                                poolsId={2}
                                                            />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[1] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVL) : ""}
                                                                // timelock={poolInfos.length > 0 ? poolInfos[1].DaysLock : ""}
                                                                timelock={120}
                                                                stakerDeposit={0}
                                                                stakerPendingsRewards={0}
                                                                stakerTimeLock={0}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[1].address : ""}
                                                                poolsId={2}
                                                            />
                                                        </>
                                                    )
                                            }
                                            <div className="mt-3 text-start text-danger" style={{
                                                padding: "10px 10px 10px 10px",
                                                borderLeft: "4px solid #d8dbe0", borderRadius: "5px", border: "1px solid red"
                                            }}>
                                                <p style={{ marginLeft: "10px", fontSize: "12px" }}>After first 45% APR 120 day-locked, %APR pays 30%APR automatically</p>
                                                <p style={{ marginLeft: "10px", fontSize: "12px" }}>New staking pays 30% APR 90 day-locked</p>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                    <Accordion.Item eventKey="2" className="itemWeb3">
                                        <Accordion.Header style={{ position: "relative" }}>
                                            <div>
                                                <div style={{ position: "absolute", top: "25px", left: "10px" }}><img src="PBX.png" alt="logo" height="30" /></div>
                                                <div style={{ position: "absolute", top: "40px", left: "24px" }}><img src="PBX.png" alt="logo" height="48" /></div>
                                                <div style={{ position: "absolute", top: "15px", left: "80px" }}>
                                                    <p><small>Stake</small> <b>PBX</b></p>
                                                    <br />
                                                    <p><small>Earn</small> <b>PBX</b></p>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ position: "absolute", top: "15px", right: "50px" }}>
                                                    <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[2].APR : ""}%</b></p>
                                                    <br />
                                                    <p><AlarmFill /> <b>{poolInfos.length > 0 ? poolInfos[2].DaysLock : ""} Days</b></p>
                                                </div>
                                            </div>
                                            <br /><br /><br /><br />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                stakerInfo.length > 0
                                                    ? (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[2] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[2].DaysLock : ""}
                                                                stakerDeposit={stakerInfo[2].deposits}
                                                                stakerPendingsRewards={stakerInfo[2].pendingsRewards}
                                                                stakerTimeLock={parseInt(stakerInfo[2].timelocks)}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[2].address : ""}
                                                                poolsId={3}
                                                            />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[2] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[2].DaysLock : ""}
                                                                stakerDeposit={0}
                                                                stakerPendingsRewards={0}
                                                                stakerTimeLock={0}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[2].address : ""}
                                                                poolsId={3}
                                                            />
                                                        </>
                                                    )
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="3" className="itemWeb3">
                                        <Accordion.Header style={{ position: "relative" }}>
                                            <div>
                                                <div style={{ position: "absolute", top: "25px", left: "10px" }}><img src="PBX.png" alt="logo" height="30" /></div>
                                                <div style={{ position: "absolute", top: "40px", left: "24px" }}><img src="PBX.png" alt="logo" height="48" /></div>
                                                <div style={{ position: "absolute", top: "15px", left: "80px" }}>
                                                    <p><small>Stake</small> <b>PBX</b></p>
                                                    <br />
                                                    <p><small>Earn</small> <b>PBX</b></p>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{ position: "absolute", top: "15px", right: "50px" }}>
                                                    <p><CurrencyExchange /> <small>APR</small> <b>{poolInfos.length > 0 ? poolInfos[3].APR : ""}%</b></p>
                                                    <br />
                                                    <p><AlarmFill /> <b>{poolInfos.length > 0 ? poolInfos[3].DaysLock : ""} Days</b></p>
                                                </div>
                                            </div>
                                            <br /><br /><br /><br />
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                stakerInfo.length > 0
                                                    ? (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[3] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[3].DaysLock : ""}
                                                                stakerDeposit={stakerInfo[3].deposits}
                                                                stakerPendingsRewards={stakerInfo[3].pendingsRewards}
                                                                stakerTimeLock={parseInt(stakerInfo[3].timelocks)}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[3].address : ""}
                                                                poolsId={4}
                                                            />
                                                        </>
                                                    )
                                                    : (
                                                        <>
                                                            <Pools
                                                                isApprove={poolApprove.length > 0 ? poolApprove[3] : false}
                                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVLlimit) : ""}
                                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVL) : ""}
                                                                timelock={poolInfos.length > 0 ? poolInfos[3].DaysLock : ""}
                                                                stakerDeposit={0}
                                                                stakerPendingsRewards={0}
                                                                stakerTimeLock={0}
                                                                poolsAddress={poolInfos.length > 0 ? poolInfos[3].address : ""}
                                                                poolsId={4}
                                                            />
                                                        </>
                                                    )
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>

                    </Container>


                    {/* {
                        stakerInfo.length > 0
                            ? (
                                <>
                                    <Container>
                                        <Row>
                                            <PoolsCard
                                                isApprove={poolApprove.length > 0 ? poolApprove[0] : false}
                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVLlimit) : ""}
                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVL) : ""}
                                                timelock={poolInfos.length > 0 ? poolInfos[0].DaysLock : ""}
                                                daysLock={180}
                                                APR={60}
                                                stakerDeposit={stakerInfo[0].deposits}
                                                stakerPendingsRewards={stakerInfo[0].pendingsRewards}
                                                stakerTimeLock={parseInt(stakerInfo[0].timelocks)}
                                                poolsAddress={poolInfos.length > 0 ? poolInfos[0].address : ""}
                                                poolsId={1}
                                            />
                                            <PoolsCard
                                                isApprove={poolApprove.length > 0 ? poolApprove[1] : false}
                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVLlimit) : ""}
                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVL) : ""}
                                                timelock={poolInfos.length > 0 ? poolInfos[1].DaysLock : ""}
                                                daysLock={120}
                                                APR={45}
                                                stakerDeposit={stakerInfo[1].deposits}
                                                stakerPendingsRewards={stakerInfo[1].pendingsRewards}
                                                stakerTimeLock={parseInt(stakerInfo[1].timelocks)}
                                                poolsAddress={poolInfos.length > 0 ? poolInfos[1].address : ""}
                                                poolsId={2}
                                            />
                                            <PoolsCard
                                                isApprove={poolApprove.length > 0 ? poolApprove[2] : false}
                                                limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVLlimit) : ""}
                                                totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVL) : ""}
                                                timelock={poolInfos.length > 0 ? poolInfos[2].DaysLock : ""}
                                                daysLock={90}
                                                APR={30}
                                                stakerDeposit={stakerInfo[2].deposits}
                                                stakerPendingsRewards={stakerInfo[2].pendingsRewards}
                                                stakerTimeLock={parseInt(stakerInfo[2].timelocks)}
                                                poolsAddress={poolInfos.length > 0 ? poolInfos[2].address : ""}
                                                poolsId={3}
                                            />
                                        </Row>
                                    </Container>
                                </>
                            )
                            : ""
                    }
                    */}
                    <FooterAPR totalTVL={maxTotalTVL} /> 
                </div>
            </div>
            <br /><br />
        </>
    );
}

export default Staking;