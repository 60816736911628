import React from "react";
// import {
//   web3,
//   web3getAccount,
//   CheckBalance,
// } from "../web3/web3Provider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card } from "react-bootstrap";


function FooterAPR(props) {
  /*
  const [totalSold, setTotalSold] = useState(0)
  // const chkTotalSolds = async () => {
  //   await totalSolds().then(async (result) => {
  //     await setTotalSold([web3.utils.fromWei(`${result}`).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")])
  //   })
  // }

  setTotalSold(1000000)
 useEffect(() => {
    setTotalSold(1000000)
    chkTotalSolds()
    web3.eth.getBlockNumber().then((res) => web3.eth.getBlock(res).then((res2) => { setCurrentTimes([res2.timestamp]); console.log(`Current block Timestamps : ${res2.timestamp} ( ${timeConverter(res2.timestamp)} )`) }));
  }, []); */
  return (
    <Container style={{ fontSize: "18px", maxWidth: "838px" }}>
      <Row>
        <Col lg="12">
          <br />
          <span style={{ letterSpacing: ".5em" }}>Total Value Lock</span>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <Card className="goldenBack text-light" style={{ backgroundColor: "#eff6ff", boxShadow: "#DF9F28 0px 0px 20px 0px" }}>
            <Card.Body>
              <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                <span style={{ marginLeft: "10px" }}> TVL :</span>
                <span>{(parseFloat(props.totalTVL)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} PBX</span>
              </div>
            </Card.Body>
          </Card>
          <br />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <br />
          <hr />
          <br />
          <span style={{ letterSpacing: ".5em" }}>THE PBX STAKING APR</span>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col lg="4">
          <Card className="goldenBack text-dark" style={{ backgroundColor: "#eff6ff", boxShadow: "#DF9F28 0px 0px 20px 0px" }}>
            <Card.Body>
              <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                <span style={{ marginLeft: "10px" }}> Yearly APR:</span>
                <span>30%</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="goldenBack text-dark" style={{ backgroundColor: "#eff6ff", boxShadow: "#DF9F28 0px 0px 20px 0px" }}>
            <Card.Body>
              <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                <span style={{ marginLeft: "10px" }}> Per Week:</span>
                <span>~ 0.652%</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col lg="4">
          <Card className="goldenBack text-dark" style={{ backgroundColor: "#eff6ff", boxShadow: "#DF9F28 0px 0px 20px 0px" }}>
            <Card.Body>
              <div className="d-flex justify-content-between" style={{ marginLeft: "10px", borderLeft: "4px solid #d8dbe0" }}>
                <span style={{ marginLeft: "10px" }}> Per Day:</span>
                <span>~ 0.082%</span>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default FooterAPR;