import React, { useState, useEffect } from "react";
import { Route, Switch } from 'react-router-dom';
import {
    web3,
    // web3getAccount,
    //   CheckBalance,
    chkApprove,
    poolsInfo,
    userInfo
} from "../web3/web3Provider";


import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Accordion, Card, Button } from "react-bootstrap";
import { List, Box, Clock, CurrencyExchange } from 'react-bootstrap-icons';
import { Link } from "react-router-dom";

// import Swal from 'sweetalert2'

import Header from '../Components/Header';
import FooterAPR from '../Components/FooterAPR';
import Pools from '../Components/Pools';
import PoolsCard from '../Components/PoolsCard';

function Staking() {
    /*
      if(typeof window.ethereum === 'undefined') {
        window.location.href = "./";
        throw new Error();
      }*/
    const [poolInfos, setPoolsInfos] = useState([]);
    const [poolApprove, setPoolApprove] = useState([]);
    const [stakerInfo, setStakerInfo] = useState([]);
    const [maxTotalTVL, setMaxTotalTVL] = useState(0);





    useEffect(() => {
        let stakerInfos = []
        const fetchAllPools = async () => {
            let prePoolsInfo = [];
            let _maxTotalTVL = 0;
            await poolsInfo(1).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(2).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(3).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            await poolsInfo(4).then((res) => setPoolsInfos(prePoolsInfo.push(res), _maxTotalTVL += parseFloat(web3.utils.fromWei(res.TVL))))
            setPoolsInfos(prePoolsInfo)
            console.log(prePoolsInfo)
            setMaxTotalTVL(_maxTotalTVL)
        }
        const fetchApproveOfAllPools = async () => {
            let preChkApprove = []
            await userInfo(1).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(2).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(3).then((res) => setStakerInfo(stakerInfos.push(res)))
            await userInfo(4).then((res) => setStakerInfo(stakerInfos.push(res)))
            setStakerInfo(stakerInfos)
            // await chkApprove(1).then((res) => poolApprove.pool1 = res )
            // await chkApprove(2).then((res) => poolApprove.pool2 = res )
            // await chkApprove(3).then((res) => poolApprove.pool3 = res )
            await chkApprove(1).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(2).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(3).then((res) => setPoolApprove(preChkApprove.push(res)))
            await chkApprove(4).then((res) => setPoolApprove(preChkApprove.push(res)))
            setPoolApprove(preChkApprove)
        }
        fetchAllPools()
        fetchApproveOfAllPools()
        console.log(stakerInfo)
        console.log(poolApprove)

    }, []);


    return (

        <>

            <Header />

            <div className="text-center">
                <p>
                    <img src="pbx.gif" alt="logo" />
                </p>
                <br />
                <br />

                <div
                    style={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        color: "rgb(90, 96, 108)",
                    }}
                >
                    <p>
                        <Link to="/list" title="List View">
                            <Button variant="secondary btnWeb3"><List style={{ fontSize: "18px" }} /> List View</Button>
                        </Link>
                        {" "}
                        <Link to="/card" title="Card View">
                            <Button variant="warning btnWeb3"><Box style={{ fontSize: "18px" }} /> Card View</Button>
                        </Link>
                    </p>
                    <br />

                    {
                        stakerInfo.length > 0
                            ? (
                                <>
                                    <Container>
                                        <Row>
                                            <Col lg="4" className="mb-3">
                                                <PoolsCard
                                                    isApprove={poolApprove.length > 0 ? poolApprove[0] : false}
                                                    limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVLlimit) : ""}
                                                    totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[0].TVL) : ""}
                                                    timelock={poolInfos.length > 0 ? poolInfos[0].DaysLock : ""}
                                                    daysLock={poolInfos.length > 0 ? poolInfos[0].DaysLock : ""}
                                                    APR={poolInfos.length > 0 ? poolInfos[0].APR : 0}
                                                    // APR={60}
                                                    stakerDeposit={stakerInfo[0].deposits}
                                                    stakerPendingsRewards={stakerInfo[0].pendingsRewards}
                                                    stakerTimeLock={parseInt(stakerInfo[0].timelocks)}
                                                    poolsAddress={poolInfos.length > 0 ? poolInfos[0].address : ""}
                                                    poolsId={1}
                                                />
                                            </Col>
                                            <Col lg="4" className="mb-3">
                                                <PoolsCard
                                                    isApprove={poolApprove.length > 0 ? poolApprove[1] : false}
                                                    limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVLlimit) : ""}
                                                    totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[1].TVL) : ""}
                                                    timelock={poolInfos.length > 0 ? poolInfos[1].DaysLock : ""}
                                                    // daysLock={poolInfos.length > 0 ? poolInfos[1].DaysLock : ""}
                                                    daysLock={120}
                                                    APR={poolInfos.length > 0 ? poolInfos[1].APR : 0}
                                                    // APR={45}
                                                    stakerDeposit={stakerInfo[1].deposits}
                                                    stakerPendingsRewards={stakerInfo[1].pendingsRewards}
                                                    stakerTimeLock={parseInt(stakerInfo[1].timelocks)}
                                                    poolsAddress={poolInfos.length > 0 ? poolInfos[1].address : ""}
                                                    poolsId={2}
                                                />
                                            </Col>
                                            <Col lg="4" className="mb-3">
                                                <PoolsCard
                                                    isApprove={poolApprove.length > 0 ? poolApprove[2] : false}
                                                    limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVLlimit) : ""}
                                                    totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[2].TVL) : ""}
                                                    timelock={poolInfos.length > 0 ? poolInfos[2].DaysLock : ""}
                                                    daysLock={poolInfos.length > 0 ? poolInfos[2].DaysLock : ""}
                                                    APR={poolInfos.length > 0 ? poolInfos[2].APR : 0}
                                                    stakerDeposit={stakerInfo[2].deposits}
                                                    stakerPendingsRewards={stakerInfo[2].pendingsRewards}
                                                    stakerTimeLock={parseInt(stakerInfo[2].timelocks)}
                                                    poolsAddress={poolInfos.length > 0 ? poolInfos[2].address : ""}
                                                    poolsId={3}
                                                />
                                            </Col>
                                            <Col lg="4" className="mb-3">
                                                <PoolsCard
                                                    isApprove={poolApprove.length > 0 ? poolApprove[3] : false}
                                                    limitStake={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVLlimit) : ""}
                                                    totalStaking={poolInfos.length > 0 ? web3.utils.fromWei(poolInfos[3].TVL) : ""}
                                                    timelock={poolInfos.length > 0 ? poolInfos[3].DaysLock : ""}
                                                    daysLock={poolInfos.length > 0 ? poolInfos[3].DaysLock : ""}
                                                    APR={poolInfos.length > 0 ? poolInfos[3].APR : 0}
                                                    stakerDeposit={stakerInfo[3].deposits}
                                                    stakerPendingsRewards={stakerInfo[3].pendingsRewards}
                                                    stakerTimeLock={parseInt(stakerInfo[3].timelocks)}
                                                    poolsAddress={poolInfos.length > 0 ? poolInfos[3].address : ""}
                                                    poolsId={4}
                                                />
                                            </Col>
                                        </Row>
                                    </Container>
                                </>
                            )
                            : ""
                    }

                    <FooterAPR totalTVL={maxTotalTVL} />
                </div>
            </div>
            <br /><br />
        </>
    );
}

export default Staking;